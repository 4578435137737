import React from 'react';
import { SectionHeader } from '../../../modules/common/components/section-header';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { Link } from 'gatsby';

import { StaticImage } from 'gatsby-plugin-image';

import '../../generic-page.scss';
import './hsr-guides.scss';
import { HSRCharacter } from '../../../modules/hsr/common/components/hsr-character';
import { Card, Col, Row, Table } from 'react-bootstrap';
import { YoutubeEmbed } from '../../../modules/common/components/youtube-embed';
import { faCookieBite, faTrophy } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CategoryCard } from '../../../modules/cs/main/components/category-card';

const HSRRerollPage: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page hsr-guide'} game="hsr">
      <ul className="breadcrumb">
        <li>
          <Link to="/star-rail/">Honkai: Star Rail</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/star-rail/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Reroll guide</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/starrail/categories/category_rerollguide.jpg"
            alt="Reroll"
          />
        </div>
        <div className="page-details">
          <h1>Honkai: Star Rail Reroll guide</h1>
          <h2>How to reroll in Honkai: Star Rail and who to aim for.</h2>
          <p>
            Last updated: <strong>25/11/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297256"></div>
      <div className="page-content">
        <SectionHeader title="Is it even worth to reroll?" />
        <p>
          The short answers is <strong>no</strong>.
        </p>
        <p>And here's the long answer:</p>
        <ul>
          <li>
            Some time ago Mihoyo{' '}
            <strong>
              removed the Guest Login option from their games and obviously the
              option is also missing in Honkai: Star Rail
            </strong>
            . This means you will need to use a different account for every
            reroll attempt (because salting isn't working here either),
          </li>
          <ul>
            <li>
              So if you're already playing Genshin Impact for example, if you
              plan to reroll, there's no way to have both games on the same
              account.
            </li>
          </ul>
          <li>
            It takes around <strong>30-40 minutes</strong> to finish the
            tutorial and reach the moment when you can actually start pulling,
          </li>
          <ul>
            <li>
              The long time is caused by the tutorial being quite story-heavy
              and you <strong>can't skip any of the conversations</strong> and
              have to click through them,
            </li>
          </ul>
          <li>
            The rates in the game are{' '}
            <strong>pretty low (0.3%/0.6% for a 5★ Character)</strong>, so it
            might take you even a whole day of rolling to get one - and it's
            high chance it will be someone you don't want as you will be able to
            only do roughly 20-22 pulls when you unlock Warping,
          </li>
          <li>
            <strong>
              You are guaranteed to obtain a 5★ Character from the Departure
              Warp Banner within the first 50 pulls.
            </strong>
          </li>
        </ul>
        <p>If you're still here, then I think this meme is perfect for you:</p>
        <StaticImage
          src="../../../images/starrail/generic/reroll_pain.webp"
          alt="Reroll Pain"
        />
        <p>So let's continue with the reroll guide!</p>
        <SectionHeader title="How to reroll" />
        <h5>Reroll methods</h5>
        <p>
          Sadly, there's just one. As we mentioned in the warning section, you
          will need to prepare a ton of dummy emails if you plan to reroll - the
          salting method is blocked, so there's no other way.
        </p>
        <p>So the rerolling process will look like this:</p>
        <ol>
          <li>Log in to the game with new mail,</li>
          <li>
            Suffer for 30-40 minutes and get finger cramps from all the clicking
            through dialogue,
          </li>
          <li>Then suffer even more as you're doing your pulls,</li>
          <li>Log out and log in with new email.</li>
        </ol>
        <SectionHeader title="The prologue" />
        <StaticImage
          src="../../../images/starrail/generic/guide_reroll_1.webp"
          alt="Reroll Pain"
        />
        <p>
          The prologue acts as tutorial in Honkai: Star Rail and as we mentioned
          earlier,{' '}
          <strong>
            it takes around 30-40 minutes to reach the part of it that unlocks
            Warping
          </strong>{' '}
          - that's how the gacha is called in this game.
        </p>
        <p>
          While playing through the tutorial in the Final Beta we tried to
          create an optimal way of progressing though it, but sadly the game
          leads you in a corridor-like route, slowly introducing the main cast
          of characters and also various features of the game: exploration,
          roles, elements, basic combat mechanics, advanced combat mechanics and
          so on.{' '}
          <strong>
            So you can't really skip any part of the tutorial and have to play
            through everything the game throws at you.
          </strong>
        </p>
        <p>
          But the worst offender that affects the length of the tutorial are
          conversations. Tons of conversations.
          <strong> And you can't skip them</strong> - you have to wait for every
          character to start saying their lines and then you can start clicking
          to interrupt them and 'move' to the next character. It's tedious.
        </p>
        <h5>Prologue steps (beware, spoilers!)</h5>
        <ol>
          <li>
            You initially play as Kafka and explore the station together with
            Silver Wolf,
          </li>
          <li>
            After a few minutes of enjoying Kafka murdering the Antimatter
            Legion, you will unlock the main character - you can pick Female or
            Male version and your choice doesn't matter (gameplay-wise at least
            - since both genders have the same skills, passives etc.),
          </li>
          <li>
            March 7th and Dan Heng will replace Kafka and Silver Wolf and
            together with them you will continue your exploration and unlock
            Traces and Light Cones,
          </li>
          <li>
            Himeko will temporarily join your team at this point and she makes
            murdering the Legion much easier,
          </li>
          <li>
            A few fights later (including a boss battle) you will meet Asta and
            finally unlock Warping.
          </li>
        </ol>
        <h5>An important tip</h5>
        <p>
          Since the majority of the tutorial is focused on fighting, make sure
          to use{' '}
          <strong>your Adventure Logs to level up the MC and Dan Heng</strong> -
          this will improve their damage output and allow you to kill the
          monsters faster.
        </p>
        <div className="fuse-ad-placeholder" data-fuse="22844297256"></div>
        <SectionHeader title="Pulling" />
        <p>
          Once you finish the tutorial and unlock Warping you will receive{' '}
          <strong>20 Star Rail Passes</strong> in your mail. Sadly, the Passes
          can't be used on Rate-Up banners as they require Star Rail Special
          Passes instead. So you're forced to pull on either the Departure Warp
          (beginner banner) or on Stellar Warp (regular banner).
        </p>
        <p>
          Also, your Trailblaze Level should be level 4 (though in some cases
          you can be only TL 3), so you might consider playing for a little bit
          more to reach TL 5 - this will give you{' '}
          <strong>
            additional 10 Star Rail Passes from the launch progression event.
          </strong>
        </p>
        <p>
          The next reward in the event is locked behind reaching TL level 15
          (which should take roughly 2-3 hours). While progressing till level 15
          will give you enough Star Rail Passes to reach the pity on the
          Departure Warp and obtain your first 5★ Character, it takes quite a
          while.
        </p>
        <p>So, now you have a few possible options:</p>
        <Table striped bordered responsive>
          <thead>
            <tr>
              <th>Name</th>
              <th>How it works</th>
              <th>Time required</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <strong>The fast and greedy reroll</strong>
              </td>
              <td>
                <p>
                  Do the 20 pulls on the Stellar Warp banner right after
                  unlocking warping. Your aim here is to start with 2x 5★
                  Characters - one from the 20 pulls done on Stellar Warp banner
                  and the other from the Departure Warp pity within 50 pulls.
                </p>
                <p>
                  Just keep in mind that it might take you a lot of tries to get
                  a 5★ from the initial 20 pulls - there's only 0.3% chance to
                  pull one.
                </p>
                <p>
                  You can also play for a 10-15 more minutes and reach TL 5 to
                  get 10 additional tickets.
                </p>
              </td>
              <td>30-40 minutes</td>
            </tr>
            <tr>
              <td>
                <strong>The patient one</strong>
              </td>
              <td>
                <p>
                  Do the 20 pulls on the Departure Warp banner right after
                  unlocking warping (while the banner has 50 pull pity, you
                  might get the 5★ character earlier).
                </p>
                <p>
                  If you're unlucky, just keep playing and you will get 10 more
                  tickets at TL 5 and another 10 tickets at TL 15. This is
                  enough to reach the 50 pity, because the banner has 20%
                  discount (you need to only use 40 tickets to do 50 pulls).
                </p>
                <p>
                  You will get one of the 7 available characters and if it's
                  someone you like - you're done! If not, you have to try again.
                </p>
              </td>
              <td>
                <ul>
                  <li>30-40 minutes (20 pulls)</li>
                  <li>50-60 minutes (30 pulls)</li>
                  <li>120-150 minutes (40 and 50 pulls)</li>
                </ul>
              </td>
            </tr>
          </tbody>
        </Table>
        <p>
          Volkin and Grim explain the reroll process and provide an alternative
          way of rolling to get the 50 pulls on the Newbie banner:
        </p>
        <Row>
          <Col xs={12} lg={6}>
            <YoutubeEmbed embedId="AM62bcX1qPg" />
          </Col>
          <Col xs={12} lg={6}>
            <YoutubeEmbed embedId="4pdPV_kP8kA" />
          </Col>
        </Row>
        <SectionHeader title="5★ Characters" />
        <p>
          You can obtain both 5★ Characters and Light Cones from both banners.
          Here's the list of possible 5★ Characters:
        </p>
        <div className="employee-container for-nikke">
          <HSRCharacter mode="icon" slug="bailu" enablePopover />
          <HSRCharacter mode="icon" slug="bronya" enablePopover />
          <HSRCharacter mode="icon" slug="clara" enablePopover />
          <HSRCharacter mode="icon" slug="gepard" enablePopover />
          <HSRCharacter mode="icon" slug="himeko" enablePopover />
          <HSRCharacter mode="icon" slug="welt" enablePopover />
          <HSRCharacter mode="icon" slug="yanqing" enablePopover />
        </div>
        <p>Information about the Standard banner:</p>
        <ul>
          <li>
            The rate for pulling a 5★ Character from this banner is currently
            set at <strong>0.3%</strong> (and 0.3% for a 5★ Light Cone),
          </li>
          <li>
            The rate for pulling a 4★ Character from this banner is currently
            set at <strong>5.1%</strong>.
          </li>
        </ul>
        <p>
          Now which of the 7 available characters is the best one? Let's go over
          each of them to show their good and bad sides!
        </p>
        <Table striped bordered responsive className="reroll-table-hsr">
          <thead>
            <tr>
              <th>Character</th>
              <th>Role</th>
              <th>Comments</th>
              <th>Reroll value</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <Card className="avatar-card">
                  <HSRCharacter
                    slug="bronya"
                    mode="icon"
                    showLabel
                    showIcon
                    enablePopover
                  />
                </Card>
              </td>
              <td>
                <strong className="amplifier">Amplifier</strong>
              </td>
              <td>
                <p>
                  While there are stronger Amplifiers available in the game
                  nowadays (ie. Ruan Mei and Robin), Bronya is still being used
                  even if she's a standard characters in quite a lot of
                  compositions - to the point of being BIS for some DPS.
                </p>
              </td>
              <td>
                <p>
                  <FontAwesomeIcon
                    icon={faTrophy}
                    width="30"
                    className="gold"
                  />
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <Card className="avatar-card">
                  <HSRCharacter
                    slug="himeko"
                    mode="icon"
                    showLabel
                    showIcon
                    enablePopover
                  />
                </Card>
              </td>
              <td>
                <strong className="dps">DPS</strong>
              </td>
              <td>
                <p>
                  Himeko is a damage dealer that focuses on doing AoE damage -
                  she can clear wave after wave of enemies without breaking a
                  sweat and she can do it with class! A top tier unit for Pure
                  Fiction endgame mode, but she struggles a bit in the two other
                  ones (MoC and AS).
                </p>
                <p>
                  She's a decent starter damage dealer that only gets stronger
                  as you invest into her and who can be played both as Crit and
                  Break DPS.
                </p>
              </td>
              <td>
                <p>
                  <FontAwesomeIcon
                    icon={faTrophy}
                    width="30"
                    className="silver"
                  />
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <Card className="avatar-card">
                  <HSRCharacter
                    slug="clara"
                    mode="icon"
                    showLabel
                    showIcon
                    enablePopover
                  />
                </Card>
              </td>
              <td>
                <strong className="dps">DPS</strong>
              </td>
              <td>
                <p>
                  Clara is an unique damage dealer as her gameplay is actually
                  the opposite of any other DPS - she just sits in the back and
                  let Svarog (her mecha protector) do the heavy lifting by
                  countering those who dared to attack Clara. She specializes in
                  AoE and Blast damage as the more enemies there are on the
                  field, the higher the chance she gets hit only for Svarog to
                  start taking his revenge.
                </p>
                <p>
                  She's also easy to build and can be even ran in a full 4-star
                  team, which makes her a great unit to build your account
                  around.
                </p>
              </td>
              <td>
                <p>
                  <FontAwesomeIcon
                    icon={faTrophy}
                    width="30"
                    className="silver"
                  />
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <Card className="avatar-card">
                  <HSRCharacter
                    slug="bailu"
                    mode="icon"
                    showLabel
                    showIcon
                    enablePopover
                  />
                </Card>
              </td>
              <td>
                <strong className="sustain">Sustain</strong>
              </td>
              <td>
                <p>
                  Since the release of Lynx, getting Bailu isn't as important
                  anymore - she's still a great Healer, but no longer a
                  priority.
                </p>
                <p>
                  Her revive passive helps you cover some mistakes as it will
                  prevent the first death of an ally in a battle, but other
                  healers offer your team much more.
                </p>
              </td>
              <td>
                <p>
                  <FontAwesomeIcon
                    icon={faTrophy}
                    width="30"
                    className="bronze"
                  />
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <Card className="avatar-card">
                  <HSRCharacter
                    slug="gepard"
                    mode="icon"
                    showLabel
                    showIcon
                    enablePopover
                  />
                </Card>
              </td>
              <td>
                <strong className="sustain">Sustain</strong>
              </td>
              <td>
                <p>
                  Gepard is a Shielder who can replace the healer in your team
                  once you invest into him, but in the early game he is
                  struggling a lot compared to them. You're better off using
                  other sustain options and then coming back to him later for
                  the hard content where he shines (like Swarm Disaster).
                </p>
              </td>
              <td>
                <p>
                  <FontAwesomeIcon
                    icon={faTrophy}
                    width="30"
                    className="bronze"
                  />
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <Card className="avatar-card">
                  <HSRCharacter
                    slug="welt"
                    mode="icon"
                    showLabel
                    showIcon
                    enablePopover
                  />
                </Card>
              </td>
              <td>
                <strong className="specialist">Specialist</strong>
              </td>
              <td>
                <p>
                  Welt is a debuffer who also can output decent damage once you
                  manage to pull a few dupes. His debuffs revolve around slowing
                  the enemy down and delaying his actions, so your team can deal
                  use all their skills before letting the boss counter attack.
                </p>
                <p>
                  Sadly he's a bit too niche with what he does and often using a
                  more generic debuffer will help your team more - especially
                  because there's a lot of great options for this role when it
                  comes to 4 star characters.
                </p>
              </td>
              <td>
                <p>
                  <FontAwesomeIcon icon={faCookieBite} width="30" />
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <Card className="avatar-card">
                  <HSRCharacter
                    slug="yanqing"
                    mode="icon"
                    showLabel
                    showIcon
                    enablePopover
                  />
                </Card>
              </td>
              <td>
                <strong className="dps">DPS</strong>
              </td>
              <td>
                <p>
                  Yanqing is a damage dealer who is the opposite of Himeko - not
                  only element wise, but also gameplay wise. He's focused on
                  dealing massive single target damage and buffing himself to do
                  even more damage.
                </p>
                <p>
                  Sadly, he aged really badly since the release and is inferior
                  to every other 5 star DPS and even more 4 star DPS. Mainly due
                  to his kit wanting him to never get hit, which is impossible
                  to do in the current state of the game - also, even if you
                  manage to do that, he still underperforms compared to others.
                </p>
              </td>
              <td>
                <p>
                  <FontAwesomeIcon icon={faCookieBite} width="30" />
                </p>
              </td>
            </tr>
          </tbody>
        </Table>
        <p>Reroll value explanation:</p>
        <ul>
          <li>
            <FontAwesomeIcon icon={faTrophy} width="18" className="gold" /> -
            The best you can go for,
          </li>
          <li>
            <FontAwesomeIcon icon={faTrophy} width="18" className="silver" /> -
            The runner-up who won't disappoint you,
          </li>
          <li>
            <FontAwesomeIcon icon={faTrophy} width="18" className="bronze" /> -
            A versatile character with something unique that makes it stand out,
          </li>
          <li>
            <FontAwesomeIcon icon={faCookieBite} width="18" /> - While they
            aren't strong enough to stand on the podium, they still have some
            good things about them that make them worth using - so they deserve
            a cookie reward!
          </li>
        </ul>
        <SectionHeader title="4★ Characters" />
        <p>
          On top of the 5★ characters who are your main reroll targets, there's
          actually quite a lot of strong 4★ ones that you might want to look out
          for. You might also want to check the Free Characters guide that lists
          all the 4★ characters you can get for free from various places:
        </p>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Free characters"
            link="/star-rail/guides/free-characters"
            image={
              <StaticImage
                src="../../../images/starrail/categories/category_freechars.png"
                alt="Free characters"
              />
            }
          />
        </Row>
        <p>
          Now let's discuss the 4★ that are a great addition to a fresh account:
        </p>
        <Table striped bordered responsive className="reroll-table-hsr">
          <thead>
            <tr>
              <th>Character</th>
              <th>Role</th>
              <th>Comments</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <Card className="avatar-card">
                  <HSRCharacter
                    slug="tingyun"
                    mode="icon"
                    showLabel
                    showIcon
                    enablePopover
                  />
                </Card>
              </td>
              <td>
                <strong className="amplifier">Amplifier</strong>
              </td>
              <td>
                <p>
                  Tingyun is the best 4★ buffer in the game who can restore
                  Energy to the main carry and allow him to use his Ultimate
                  more often. Thanks to that and the other buffs she can
                  provide, she's easy to slot in a lot of teams and is a
                  powerhouse from early to endgame.
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <Card className="avatar-card">
                  <HSRCharacter
                    slug="gallagher"
                    mode="icon"
                    showLabel
                    showIcon
                    enablePopover
                  />
                </Card>
              </td>
              <td>
                <strong className="sustain">Sustain</strong>
              </td>
              <td>
                <p>
                  Gallagher is the best 4★ Healer in the game who can even
                  compete with Limited Healers. He's very strong generic healer
                  who also has his own niche in the Break team - the best
                  carrier of QPQ Light Cone (helps with energy issues).
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <Card className="avatar-card">
                  <HSRCharacter
                    slug="pela"
                    mode="icon"
                    showLabel
                    showIcon
                    enablePopover
                  />
                </Card>
              </td>
              <td>
                <strong className="amplifier">Amplifier</strong>
              </td>
              <td>
                <p>
                  Pela is a debuffer who can reduce enemies' DEF which is one of
                  the best ways to scale your team's damage. She's easy to use,
                  easy to build and doesn't require Eidolons, which makes her a
                  great pick for new players.
                </p>
              </td>
            </tr>
          </tbody>
        </Table>
        <div className="fuse-ad-placeholder" data-fuse="22844297256"></div>
      </div>
    </DashboardLayout>
  );
};

export default HSRRerollPage;

export const Head: React.FC = () => (
  <Seo
    title="Reroll | Honkai: Star Rail | Prydwen Institute"
    description="How to reroll in Honkai: Star Rail."
  />
);
